(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@audi/audi-ui-react"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@audi/audi-ui-react", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("@audi/audi-ui-react"), require("styled-components")) : factory(root["react"], root["@audi/audi-ui-react"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__9297__, __WEBPACK_EXTERNAL_MODULE__8350__, __WEBPACK_EXTERNAL_MODULE__9914__) {
return 