import * as React from 'react';

import { ReactComponent as CloseButtonSvg } from '../assets/cancel-small.svg';
import { audiDarkTheme } from '@audi/audi-ui-react';
import styled from 'styled-components';

const { m: breakpointM } = audiDarkTheme.breakpoints;

const CloseButton = styled.a`
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
  @media (min-width: ${breakpointM}px) {
    position: absolute;
    top: 0;
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

interface IClose {
  onClose: (e) => void;
}

const Close: React.FC<IClose> = (props) => {
  const { onClose } = props;
  return (
    <CloseButtonWrapper>
      <CloseButton
        aria-label="close button"
        data-testid="one-layer-close"
        onClick={onClose}
        role="button"
        title="close button"
      >
        <CloseButtonSvg />
      </CloseButton>
    </CloseButtonWrapper>
  );
};

export default Close;
