import * as React from 'react';

import { ALERT, LayerTypes } from './globals';
import OneLayerInner, {
  Animation,
  AnimationWrapper,
  ScrollableContent,
} from './one-layer-inner';
import { useCallback, useEffect, useRef } from 'react';

import Close from './close';
import OneLayerContent from './one-layer-content';
import OneLayerOuter from './one-layer-outer';
import debounce from 'lodash.debounce';

export interface IOneLayer {
  showCloseButton: boolean;
  close: () => void;
  type: LayerTypes;
  zIndexAlert: number;
  zIndex?: number;
  active: boolean;
  setStartHideAnimation: (start: boolean) => void;
  hideAnimation: boolean;
  openAnimation: boolean;
  showAnimation: boolean;
  setScrollOffset: (id: string, n: number) => void;
  id: string;
  scrollOffset: number;
  index: number;
}

export const OneLayer: React.FC<IOneLayer> = ({
  close,
  type,
  children,
  showCloseButton,
  zIndexAlert,
  zIndex,
  setStartHideAnimation,
  hideAnimation,
  openAnimation,
  showAnimation,
  active,
  setScrollOffset,
  id,
  scrollOffset,
  index,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const refCopy = ref.current;

    const listener = debounce((): void => {
      setScrollOffset(id, refCopy.scrollTop);
    }, 250);

    if (active && refCopy) {
      refCopy.addEventListener('scroll', listener);
    }

    return (): void => {
      if (active && refCopy) {
        refCopy.removeEventListener('scroll', listener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, active]);

  useEffect(() => {
    ref.current.scrollTop = scrollOffset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLayerClickInner = useCallback((e): void => {
    e.stopPropagation();
  }, []);

  const onLayerClickOuter = useCallback(
    (e): void => {
      e.stopPropagation();
      setStartHideAnimation(true);
    },
    [setStartHideAnimation],
  );

  return (
    <OneLayerOuter
      data-fefa-layer-active="true"
      data-one-layer="true"
      data-testid={`one-layer-outer-${index}`}
      onClick={onLayerClickOuter}
      type={type}
      zIndex={type === ALERT ? zIndexAlert : zIndex}
    >
      <AnimationWrapper type={type}>
        <Animation
          className={openAnimation ? 'oneLayerOpenAnimation' : null}
          data-testid="open-animation-wrapper"
        >
          <Animation
            className={showAnimation ? 'oneLayerShowAnimation' : null}
            data-testid="show-animation-wrapper"
          >
            <Animation
              className={hideAnimation ? 'oneLayerHideAnimation' : null}
              data-testid="hide-animation-wrapper"
              onAnimationEnd={(): void => {
                if (hideAnimation) {
                  setStartHideAnimation(false);
                  if (active) {
                    close();
                  }
                }
              }}
            >
              <OneLayerInner
                data-testid="one-layer-inner"
                onClick={onLayerClickInner}
                onMouseDown={onLayerClickInner}
                onTouchStart={onLayerClickInner}
                type={type}
              >
                {showCloseButton && <Close onClose={onLayerClickOuter} />}
                <ScrollableContent
                  data-scroll-context={true}
                  data-testid="one-layer-scrollable-content"
                  ref={ref}
                >
                  <OneLayerContent
                    data-testid={`one-layer-content-${index}`}
                    type={type}
                  >
                    {children}
                  </OneLayerContent>
                </ScrollableContent>
              </OneLayerInner>
            </Animation>
          </Animation>
        </Animation>
      </AnimationWrapper>
    </OneLayerOuter>
  );
};
