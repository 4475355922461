import { ALERT, FOCUS_LARGE, FOCUS_SMALL, LayerTypes } from './globals';

import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';

const stylesContent = {
  [ALERT]: {
    m: '32px 48px 48px 48px',
    s: '16px 28px 28px 28px',
    xs: '12px 16px 16px 16px',
  },
  [FOCUS_LARGE]: {
    l: '48px 60px 60px 60px',
    m: '32px 48px 48px 48px',
    s: '16px 28px 28px 28px',
    xs: '12px 16px 16px 16px',
  },
  [FOCUS_SMALL]: {
    l: '48px 60px 60px 60px',
    m: '32px 48px 48px 48px',
    s: '16px 28px 28px 28px',
    xs: '12px 16px 16px 16px',
  },
};

const OneLayerContent = styled.div<{ type: LayerTypes }>`
  background-color: #fff;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ type, theme }): string =>
    stylesContent[type] &&
    responsiveStyles({ padding: stylesContent[type] }, theme.breakpoints)}
`;

export default OneLayerContent;
