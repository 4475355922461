import {
  ALERT,
  FOCUS_LARGE,
  FOCUS_SMALL,
  LayerTypes,
  oneLayerTimingsConfig as timeConfig,
} from './globals';
import { TokenizedTheme, responsiveStyles } from '@audi/audi-ui-react';
import styled, { keyframes } from 'styled-components';

interface Sizes {
  m?: string;
  l?: string;
  xs?: string;
}

interface Itypes {
  'max-width'?: Sizes;
  width: Sizes;
}
interface IstylesInner {
  [ALERT]: Itypes;
  [FOCUS_LARGE]: Itypes;
  [FOCUS_SMALL]: Itypes;
}

const stylesInner = (theme: TokenizedTheme): IstylesInner => ({
  [ALERT]: {
    'max-width': {
      m: '600px',
    },
    width: {
      xs: '100%',
    },
  },
  [FOCUS_LARGE]: {
    width: {
      l: `${theme.breakpoints.l}px`,
      xs: '100%',
    },
  },
  [FOCUS_SMALL]: {
    width: {
      m: `${theme.breakpoints.m}px`,
      xs: '100%',
    },
  },
});

const openKeyFramesSmall = keyframes`
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
`;

const openKeyFramesLarge = keyframes`
  0% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const hideKeyFrames = keyframes`
  0% {
    transform: scale(0.9, 0.9) translateY(40px);
  }

  100% {
    transform: scale(1, 1) translateY(0px);
  }
`;

const showKeyFrames = keyframes`
  0% {
    transform: scale(0.9, 0.9) translateY(40px);
  }

  100% {
    transform: scale(1, 1) translateY(0px);
  }
`;

const opacityKeyFrames = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const AnimationWrapper = styled.div<IOneLayerInner>`
  max-height: 100%;

  ${({ type, theme }): string =>
    stylesInner(theme)[type] &&
    responsiveStyles(
      { width: stylesInner(theme)[type].width },
      theme.breakpoints,
    )};

  .oneLayerShowAnimation {
    animation-delay: 0s, 0s;
    animation-duration: ${timeConfig.XL}ms, ${timeConfig.L}ms;
    animation-fill-mode: forwards;
    animation-name: ${showKeyFrames}, ${opacityKeyFrames};
    animation-timing-function: ${({ theme }): string => theme.easing};
  }

  .oneLayerHideAnimation {
    animation-delay: 0s, ${timeConfig.M}ms;
    animation-direction: reverse;
    animation-duration: ${timeConfig.XL}ms, ${timeConfig.L}ms;
    animation-fill-mode: forwards;
    animation-name: ${hideKeyFrames}, ${opacityKeyFrames};
    animation-timing-function: ${({ theme }): string => theme.easing};
  }

  .oneLayerOpenAnimation {
    animation-duration: ${timeConfig.XL}ms;
    animation-fill-mode: forwards;
    animation-name: ${openKeyFramesSmall};
    animation-timing-function: ${({ theme }): string => theme.easing};
    @media (min-width: ${({ theme }): string => theme.breakpoints.m}px) {
      animation-duration: ${timeConfig.XL}ms, ${timeConfig.L}ms;
      animation-name: ${openKeyFramesLarge}, ${opacityKeyFrames};
    }
  }
`;
interface IOneLayerInner {
  type: LayerTypes;
}
const OneLayerInner = styled.div<IOneLayerInner>`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  position: relative;
  width: 100%;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ type, theme }): string =>
    stylesInner(theme)[type] &&
    stylesInner(theme)[type]['max-width'] &&
    responsiveStyles(
      { ['max-width']: stylesInner(theme)[type]['max-width'] },
      theme.breakpoints,
    )}
`;

export const Animation = styled.div`
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: 100%;
`;

export const ScrollableContent = styled.div`
  max-height: 100vh;
  overflow-y: auto;
`;

export default OneLayerInner;
