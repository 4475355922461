import * as React from 'react';

import styled, { keyframes } from 'styled-components';

import { LayerDescriptionV2 } from '@volkswagen-onehub/layer-manager';
import { oneLayerTimingsConfig } from './globals';
import { useEffect } from 'react';

interface ShaderProps {
  zIndex: number;
  layers?: LayerDescriptionV2<unknown>[];
  featureAppId: string;
}

const openKeyFramesShader = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const ShaderStyled = styled.div<ShaderProps>`
  animation-duration: ${oneLayerTimingsConfig.L}ms;
  animation-name: ${openKeyFramesShader};
  animation-timing-function: ${({ theme }): string => theme.easing};
  background-color: rgba(0, 0, 0, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ zIndex }): string => zIndex && `z-index: ${zIndex};`};
`;

const Shader: React.FC<ShaderProps> = (props) => {
  const { layers, featureAppId } = props;

  useEffect(() => {
    history?.pushState({ id: featureAppId }, null, location.href);
    return (): void => {
      if (history?.state?.id === featureAppId) {
        history.back();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const closeAll = (): void => {
      let i: number;
      for (i = layers.length - 1; i > -1; i--) {
        layers[i]?.layer?.close();
      }
    };
    /* istanbul ignore next */
    window?.addEventListener('popstate', closeAll, false);
    return (): void => {
      /* istanbul ignore next */
      window?.removeEventListener('popstate', closeAll, false);
    };
  }, [layers]);

  return <ShaderStyled {...props} />;
};

export default Shader;
