/* eslint-disable max-statements */
import * as React from 'react';

import { ALERT, FOCUS, FOCUS_LARGE, FOCUS_SMALL } from './globals';
import {
  DisclaimerLayerDescriptionV2,
  FocusLayerSizeV2,
  LayerDescriptionV2,
} from '@volkswagen-onehub/layer-manager';
import { useEffect, useMemo } from 'react';

import { OneLayer } from './one-layer';

interface IOneLayerManager {
  layerDescription: LayerDescriptionV2 | DisclaimerLayerDescriptionV2;
  active: boolean;
  zIndexAlert: number;
  zIndex?: number;
  layerLength: number;
  setStartHideAnimation: (state: boolean) => void;
  startHideAnimation: boolean;
  scrollOffset?: number;
  setScrollOffset: (id: string, n: number) => void;
  layerHistory: number[];
  index: number;
}

const OneLayerManager: React.FC<IOneLayerManager> = (props) => {
  const {
    layerDescription,
    active,
    zIndexAlert,
    zIndex,
    layerLength,
    setStartHideAnimation,
    startHideAnimation,
    setScrollOffset,
    layerHistory,
    scrollOffset,
    index,
  } = props;
  const { close, options, id } = layerDescription.layer;

  const type = useMemo(() => {
    if (layerDescription.type === ALERT) {
      return ALERT;
    }
    if (layerDescription.type === FOCUS) {
      if (layerDescription.layer.options.size === FocusLayerSizeV2.A) {
        return FOCUS_LARGE;
      }
      return FOCUS_SMALL;
    }
    return undefined;
  }, [layerDescription]);

  const showCloseButton = useMemo(() => {
    if (type === ALERT) {
      return false;
    }
    return options.userCloseable;
  }, [options, type]);

  useEffect(() => {
    if (startHideAnimation && layerLength === 1) {
      // CASE: close one layer immediately
      close();
      setStartHideAnimation(false);
    }
  }, [close, layerLength, setStartHideAnimation, startHideAnimation]);

  const lastHistoryElement = layerHistory[layerHistory.length - 1];
  const secondLastElement = layerHistory[layerHistory.length - 2];

  const showAnimation = useMemo(
    () =>
      layerLength > 1 &&
      ((startHideAnimation && !active) || (!startHideAnimation && active)),
    [active, layerLength, startHideAnimation],
  );

  const invisible = useMemo(() => {
    return (
      layerLength > 1 &&
      (layerLength < lastHistoryElement ||
        (lastHistoryElement < secondLastElement &&
          layerLength === lastHistoryElement)) &&
      !active &&
      !showAnimation
    );
  }, [
    layerLength,
    active,
    showAnimation,
    lastHistoryElement,
    secondLastElement,
  ]);

  const hideAnimation = useMemo(
    () =>
      layerLength > 1 &&
      ((startHideAnimation && active) || (!startHideAnimation && !active)) &&
      !invisible,
    [active, layerLength, startHideAnimation, invisible],
  );

  const openAnimation = useMemo(() => {
    const isOpen =
      layerLength === 1 &&
      (layerHistory.length === 1 ||
        (lastHistoryElement === 1 && layerHistory.length === 2));

    return isOpen && !showAnimation && !hideAnimation;
  }, [
    hideAnimation,
    showAnimation,
    layerLength,
    layerHistory,
    lastHistoryElement,
  ]);

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if (active && e.key === 'Escape') {
        setStartHideAnimation(true);
      }
    };
    /* istanbul ignore next */
    document?.addEventListener('keydown', listener, false);
    return (): void => {
      /* istanbul ignore next */
      document?.removeEventListener('keydown', listener, false);
    };
  }, [active, setStartHideAnimation]);

  if (!type || invisible) {
    return null;
  }

  return (
    <OneLayer
      active={active}
      close={close}
      hideAnimation={hideAnimation}
      id={id}
      index={index}
      openAnimation={openAnimation}
      scrollOffset={scrollOffset}
      setScrollOffset={setScrollOffset}
      setStartHideAnimation={setStartHideAnimation}
      showAnimation={showAnimation}
      showCloseButton={showCloseButton}
      type={type}
      zIndex={zIndex}
      zIndexAlert={zIndexAlert}
    >
      {layerDescription.layer.render()}
    </OneLayer>
  );
};

export default OneLayerManager;
