import { ALERT, FOCUS_LARGE, FOCUS_SMALL, LayerTypes } from './globals';
import { responsiveStyles } from '@audi/audi-ui-react';

import styled from 'styled-components';

const paddingShader = {
  [ALERT]: {
    m: '16% 0',
    xs: '16% 0 0 0',
  },
  [FOCUS_LARGE]: {
    xs: '0',
  },
  [FOCUS_SMALL]: {
    m: '4% 0',
    xs: '4% 0 0 0',
  },
};

const justifyContent = {
  m: 'center',
  xs: 'flex-end',
};

interface IOneLayerOuter {
  type: LayerTypes;
  zIndex?: number;
}

const OneLayerOuter = styled.div<IOneLayerOuter>`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ zIndex }): string => zIndex && `z-index: ${zIndex};`};

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${({ type, theme }): string =>
    paddingShader[type] &&
    responsiveStyles({ padding: paddingShader[type] }, theme.breakpoints)}

  ${({ theme }): string =>
    responsiveStyles({ 'justify-content': justifyContent }, theme.breakpoints)}
`;

export default OneLayerOuter;
