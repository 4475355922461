/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
// WebPack generation of HTML files under `/demo`
import './demo/index.html';

import * as React from 'react';

import { AudiPlatformProvider, audiDarkTheme } from '@audi/audi-ui-react';
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from '@feature-hub/core';
import { LayerManagerV24 } from '@volkswagen-onehub/layer-manager';

import OneLayerCollection from './components/one-layer-collection';
import { ReactFeatureApp } from '@feature-hub/react';

interface Dependencies extends FeatureServices {
  readonly 's2:logger': Logger;
  readonly 'layer-manager': LayerManagerV24;
}

interface Config {
  zIndexAlert: number;
  zIndex?: number;
}

type FeatureAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Config
>;

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices: services, config, featureAppId }) => {
    const zIndexAlert = config?.zIndexAlert || 100;
    const zIndex = config?.zIndex;

    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <AudiPlatformProvider theme={audiDarkTheme}>
          <OneLayerCollection
            {...{ featureAppId, zIndex, zIndexAlert }}
            layerManager={services['layer-manager']}
          />
        </AudiPlatformProvider>
      ),
    };
  },

  dependencies: {
    externals: { react: '^16.13.0' },
    featureServices: {
      'layer-manager': '^2.4.0',
      's2:logger': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {},
  },
};

export default featureAppDefinition;
